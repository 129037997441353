<template>
  <div>
    <el-page-header @back="goBack"></el-page-header>
    <el-main>
      <TableWrap @callback="handlePagination" :total="total">
        <template slot="tableOperate">
          <el-button type="primary" size="small" @click="addDic"
            >新增</el-button
          >
          <el-button type="primary" size="small" @click="updateDic"
            >修改</el-button
          >
        </template>
        <el-table
          :data="tableData"
          v-loading="loading"
          :header-cell-style="{ background: '#FAFAFA' }"
          style="width: 100%; margin-top: 15px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50px"></el-table-column>
          <el-table-column label="状态" prop="status">
            <template v-slot="scope">{{
              $utils.getDicValue('SYSTEM_NORMAL_STATUS', scope.row.status).mean
            }}</template>
          </el-table-column>
          <el-table-column label="排序号" prop="orderNum"></el-table-column>
          <el-table-column label="编码" prop="code"></el-table-column>
          <el-table-column label="字典值" prop="val"></el-table-column>
          <el-table-column label="表示内容" prop="mean"></el-table-column>
          <el-table-column label="字典值描述" prop="desc"></el-table-column>
          <el-table-column label="作用空间" prop="scope"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="dicDel(scope.row)" size="small">
                <span style="color: #f5222d"> 删除 </span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </TableWrap>

      <!-- 抽屉：新增、修改-->
    </el-main>
    <dicDrawer
      ref="dicDrawer"
      :dictionary="tableData"
      :formData="dicItemDrawer"
      :temp="temp"
      :operateTitle="operateTitle"
      @callback="handleInit"
    />
  </div>
</template>

<script>
import dicDrawer from './dicItemDrawer'
import tableMixin from '@/mixIn/tableMixIn'
import {
    reqDicItem,
    reqDicItemDelById,
    reqDicItemGetById,
} from '@/api/system/dictionaryItem'

export default {
    // 字典项
    name: 'dictionaryItem',

    mixins: [tableMixin],

    components: {
        dicDrawer,
    },

    data () {
        return {
            reqTableData: reqDicItem,
            inline: true, // 搜索按钮是否换行
            searchItem: '',
            drawerVisible: false,
            operateTitle: '', // 弹框标题
            temp: 0, // 1--修改,2--新增
            // 字典项抽屉显示信息
            dicItemDrawer: {
                status: '0', // 状态
                orderNum: '', // 排序号
                code: '', // 编码
                val: '', // 字典值
                mean: '', // 表示内容
                desc: '', // 字典值描述
                spoce: '', // 作用空间
                dictionaryName: '', // 字典名称
                dictionaryCode: '', // 字典编码
                id: '', // 主键标识
            },
            statusList: [],
            currentPage: 1,
            pageSize: 20
        }
    },

    created () {
        this.handleInit()
    },

    methods: {
    // 获取表格数据
        handleInit () {
            this.$utils.getDicData('SYSTEM_NORMAL_STATUS').then((res) => {
                const id = this.$route.query.id
                this.query.appCode = this.$route.query.appCode
                if (this.query.appCode) {
                    reqDicItem({ id, ...this.query }).then((res) => {
                        this.tableData = res.data
                        this.tableData = this.tableData.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize)
                        this.total = res.data.length
                    })
                } else {
                    this.query.appCode = ''
                    reqDicItem({ id, ...this.query }).then((res) => {
                        // console.log(res)
                        this.tableData = res.data
                        this.tableData = this.tableData.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 1) * this.pageSize + this.pageSize)
                        this.total = res.data.length
                    })
                }
            })
        },
        handlePagination (data) {
            this.pageSize = data.pageSize
            this.currentPage = data.currentPage
            this.handleInit()
        },
        // 获取字典项数据
        getTableData () {
            const { query } = this
            this.loading = true
            this.reqTableData({ ...query })
                .then(({ data }) => {
                    this.tableData = data
                    console.log(data)
                    this.loading = false
                })
                .catch(() => {
                    this.loading = false
                })
        },

        // 返回
        goBack () {
            this.$router.go(-1)
        },

        // 新增
        addDic () {
            this.operateTitle = 'add'
            this.dicItemDrawer.dictionaryName = this.$route.query.name
            this.dicItemDrawer.dictionaryCode = this.$route.query.dictionaryCode
            if (this.$route.query.appCode) {
                this.dicItemDrawer.appCode = this.$route.query.appCode
            }
            this.$refs.dicDrawer.drawerVisible = true
            this.temp = 0
        },

        // 修改
        updateDic () {
            const length = this.multipleSelection.length
            if (length === 0) {
                this.$message({
                    type: 'warning',
                    message: '请至少选择一个修改项！',
                })
            } else if (length > 1) {
                this.$message({
                    type: 'warning',
                    message: '最多选择一个修改项!',
                })
            } else {
                this.operateTitle = 'update'
                this.temp = 1
                // this.dicItemDrawer = { ...this.multipleSelection[0] }
                reqDicItemGetById({ id: this.multipleSelection[0].id }).then((res) => {
                    this.dicItemDrawer = res.data
                    this.dicItemDrawer.status = `${this.dicItemDrawer.status}`
                })
                this.dicItemDrawer.dictionaryName = this.$route.query.name
                this.$refs.dicDrawer.drawerVisible = true
            }
        },

        // 删除
        dicDel (row) {
            this.$confirm('是否确认删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    reqDicItemDelById({ id: row.id }).then((result) => {
                        this.handleInit()
                        this.$message({
                            type: 'success',
                            message: '删除成功',
                        })
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
    },
}
</script>

<style scoped></style>
