import { render, staticRenderFns } from "./dictionaryItem.vue?vue&type=template&id=02dc1a16&scoped=true&"
import script from "./dictionaryItem.vue?vue&type=script&lang=js&"
export * from "./dictionaryItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02dc1a16",
  null
  
)

export default component.exports