<template>
    <div>
        <el-drawer
            :title="title"
            :visible.sync="drawerVisible"
            :wrapperClosable="false"
            direction="rtl"
            custom-class="cus-drawer">
        <!-- <div class="cus_drawer_container"> -->
            <div class="cus_drawer_content">
            <!-- ----------------------字典项-------------------- -->
                <el-form
                    label-position="right"
                    :model="formData"
                    ref="formData"
                    :rules="rules">
                    <el-form-item label="编码" prop="code">
                        <el-input
                            size="small"
                            v-model="formData.code"
                            placeholder="请输入编码"
                            :disabled="detail"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="字典名称" prop="dictionaryName">
                        <el-input v-model="formData.dictionaryName" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="字典项值" prop="val">
                        <el-input
                            size="small"
                            v-model="formData.val"
                            placeholder="请输入字典项值"
                            :disabled="detail"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="表示内容" prop="mean">
                        <el-input
                            size="small"
                            v-model="formData.mean"
                            placeholder="请输入表示内容"
                            :disabled="detail"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="字典项描述" prop="desc">
                        <el-input
                            size="small"
                            v-model="formData.desc"
                            placeholder="请输入字典项描述"
                            :disabled="detail"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="排序号" prop="orderNum">
                        <el-input
                            size="small"
                            v-model="formData.orderNum"
                            placeholder="请输入排序号(限制整数)"
                            :disabled="detail"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="作用空间" prop="scope">
                        <el-input
                            size="small"
                            v-model="formData.scope"
                            placeholder="请输入作用空间"
                            :disabled="detail"
                        ></el-input>
                    </el-form-item> -->

                    <el-form-item label="状态" prop="status">
                        <el-select
                            size="small"
                            style="width:100%;"
                            v-model="formData.status"
                            placeholder="请选择"
                            :disabled="detail"
                        >
                            <el-option v-for="item in selectList" :key="item.val" :label="item.mean" :value="item.val"></el-option>

                        </el-select>
                    </el-form-item>
                </el-form>
                <!-- </div> -->
                <footer class="cus_drawer_footer">
                    <el-button size="small" @click="cancelDicDrawer">取消</el-button>
                    <el-button size="small" type="primary" v-if="!detail" @click="submitDic">提交</el-button>
                </footer>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import dayjs from 'dayjs'
import { reqDicItemAdd, reqDicItemUpdate } from '@/api/system/dictionaryItem'

export default {
    // 抽屉
    name: 'dicDrawer',

    props: {
        formData: { type: Object }, // 抽屉呈现信息
        callback: Function, // 回调方法--实现数据在页面表格的同步更新
        detail: { // 输入框启用/禁用
            type: Boolean,
            default: false
        },
        // 标题
        operateTitle: {
            type: String,
            default: ''
        },
        // 新增、修改切换
        temp: {
            type: Number,
            default: 0
        },
    },

    data () {
        return {
            drawerVisible: false,
            // 抽屉表单规则
            rules: {
                /* ---------字典项--------- */
                // 编码规则--???
                // 表示内容
                mean: [{ required: true, message: '请输入表示内容', trigger: 'blur' }],
                // 字典项值
                val: [{ required: true, message: '请输入字典值', trigger: 'blur' }],

            },
            title: '',
            selectList: [],
            // 提交校验时判断新增修改

        }
    },
    watch: {
        drawerVisible () {
            this.$utils.getDicData('SYSTEM_NORMAL_STATUS')
                .then((result) => {
                    this.selectList = result
                })
        },

    },

    methods: {
        // 关闭抽屉时，清空信息
        clear () {
            for (const i in this.formData) {
                this.formData[i] = ''
            }
        },

        // 抽屉--取消
        cancelDicDrawer () {
            this.clear()
            this.$refs.formData.clearValidate()
            this.drawerVisible = false
        },

        // 提交校验
        submitDic () {
            this.$refs.formData.validate(valid => {
                if (valid) {
                    if (this.temp === 0) {
                        this.formData.createTime = dayjs(Date()).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                        // this.formData.dictionaryId = Number(this.$route.query.id)
                        this.formData.orderNum = Number(this.formData.orderNum)
                        reqDicItemAdd(this.formData)
                            .then(result => {
                                this.$emit('callback')
                                console.log(result)
                            })
                    } else {
                        // 修改
                        this.formData.updateTime = dayjs(Date()).format('YYYY-MM-DD HH:mm:ss')
                        reqDicItemUpdate(this.formData)
                            .then(result => { this.$emit('callback') })
                    }
                    this.drawerVisible = false
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .cus-over{
    overflow: auto;
}
</style>
