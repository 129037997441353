import request from '@/api/request'

/* 通过字典id查询对应字典项 */
export function reqDicItem (id) { return request({ url: '/dictionary/:id/items', params: id }) }

// /* 字典项分页查询 */
// export function reqDicItem () { return request({ url: '/dictionaryitem/page' }) }

/* 新增字典项 */
export function reqDicItemAdd (params) { return request({ url: '/dictionaryitem', method: 'post', params }) }

/* 修改字典项 */
export function reqDicItemUpdate (params) { return request({ url: '/dictionaryitem', method: 'put', params }) }

/* 通过主键id查询--详情 */
export function reqDicItemGetById (id) { return request({ url: '/dictionaryitem/:id', params: id }) }

/* 通过主键id删除 */
export function reqDicItemDelById (id) { return request({ url: '/dictionaryitem/:id', method: 'delete', params: id }) }
